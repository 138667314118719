"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Color {
    constructor(name) {
        this.colors = [];
        this.name = name;
    }
    add(idx, color) {
        const c = this.colors.find(item => item.idx === idx);
        if (c) {
            c.color = color.color;
        }
        else {
            this.colors.push({ idx, color: color.color });
        }
    }
    get(i, opacity = 1) {
        switch (i) {
            case 0:
                return this.colors[1].color.toString(opacity);
            case 1:
                return this.colors[2].color.toString(opacity);
            case 2:
                return this.colors[0].color.toString(opacity);
            case 3:
                return this.colors[3].color.toString(opacity);
            case 4:
                return this.colors[4].color.toString(opacity);
        }
        throw new Error('Color not found');
    }
}
exports.Color = Color;
