"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const NmeaPositionCollection_1 = require("../NmeaPosition/NmeaPositionCollection");
const NmeaPositionFeature_1 = require("./NmeaPositionFeature");
class NmeaPositionFeatureCollection extends NmeaPositionCollection_1.NmeaPositionCollection {
    model(data) {
        return new NmeaPositionFeature_1.NmeaPositionFeature(this, data);
    }
    // ***************************************
    // GeoJSON
    // ***************************************
    async toTrack(d = 5, color) {
        let track = [];
        let idx = 0;
        for (const current of this._collection) {
            if (++idx < this.collection.length) {
                const feature = await current.toTrackFragment(d, color);
                track = track.concat(feature);
            }
        }
        return track;
    }
    async toFeatureCollection(d = 5, color) {
        let features = [];
        let idx = 0;
        for (const current of this._collection) {
            if (idx > 0) {
                const fragment = await current.toFeature(d, color);
                if (fragment) {
                    features = features.concat(fragment);
                }
            }
            idx++;
        }
        return features;
    }
}
exports.NmeaPositionFeatureCollection = NmeaPositionFeatureCollection;
