"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AbstractNmeaCollection_1 = require("../AbstractNmea/AbstractNmeaCollection");
const NmeaDefault_1 = require("./NmeaDefault");
class NmeaDefaultCollection extends AbstractNmeaCollection_1.AbstractNmeaCollection {
    constructor() {
        super(...arguments);
        this.collectionName = 'defaults';
    }
    model(data) {
        return new NmeaDefault_1.NmeaDefault(this, data);
    }
    // ***************************************
    // Storage
    // ***************************************
    async create(current) {
        let res;
        try {
            const data = await this.database.create(this.collectionName, current.toDB());
            res = this.model(data);
        }
        catch (ex) {
            res = this.model(current);
        }
        this.info('NmeaDefault created', current);
        return res;
    }
    // ***************************************
    // Reporting
    // ***************************************
    warn(message, current) {
        if (this.logger.level < 1) {
            return;
        }
        if (!current) {
            this.logger.warn(message);
            return;
        }
        this.logger.warn(message, current && current.toInfo());
    }
    info(message, current) {
        if (this.logger.level === 4) {
            this.logger.verbose(message, current && current.toInfo());
        }
        if (this.logger.level === 5) {
            this.logger.debug(message, current && current.toReport());
        }
    }
}
exports.NmeaDefaultCollection = NmeaDefaultCollection;
