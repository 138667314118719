"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const events_1 = require("events");
class AbstractNmeaCollection extends events_1.EventEmitter {
    constructor(db, logger) {
        super();
        this.collectionName = 'defaults';
        this.Sender = '';
        this.database = db;
        this.Sender = db.sender;
        this.logger = logger;
    }
    // ***************************************
    // Reporting
    // ***************************************
    warn(message, current) {
        if (this.logger.level < 1) {
            return;
        }
        if (!current) {
            this.logger.warn(message);
            return;
        }
        this.logger.warn(message, current.toInfo());
    }
    info(message, current) {
        if (this.logger.level < 4) {
            this.logger.info(message, current && current.toInfo());
        }
        if (this.logger.level > 3) {
            this.logger.verbose(message, current && current.toReport());
        }
    }
}
exports.AbstractNmeaCollection = AbstractNmeaCollection;
