"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AbstractNmeaModel_1 = require("./AbstractNmeaModel");
class AbstractNmeaIterableModel extends AbstractNmeaModel_1.AbstractNmeaModel {
    _toReportDiff(Description, fields, other) {
        const plen = 46;
        const plen2 = 27;
        const pchr = '.';
        const pchr2 = '.';
        const s = `${this.AIS},${this.Channel},${String(this.Revision).padStart(3, '0')}`;
        const o = `${other.AIS},${this.Channel},${String(other.Revision).padStart(3, '0')}`;
        const report = this.toReportHeader();
        report.push(''.padEnd(100, '-'));
        report.push('AIS,Channel,Revision'.padEnd(plen, pchr) + ': ' + s.padEnd(plen2, pchr2) + o);
        for (const field of fields) {
            if (this[field] !== other[field]) {
                report.push(Description[field].padEnd(plen, pchr)
                    + ': '
                    + this.format(field).padEnd(plen2, pchr2)
                    + other.format(field));
            }
        }
        return report;
    }
}
exports.AbstractNmeaIterableModel = AbstractNmeaIterableModel;
