"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackButtonView = void 0;
const index_1 = require("../lib/index");
class TrackButtonView extends index_1.AbstractButtonView {
    constructor(selector, ship) {
        super(selector);
        this.name = 'TrackButtonView';
        this.textA = 'Track';
        this.textB = 'Track';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.init = false;
        this.wait = false;
        this.has = false;
        this.enable = async (ev) => {
            this.wait = false;
            await this.render(ev);
        };
        this.update = (ev) => {
            this.broadcast('request:track:mmsi', { MMSI: this.ship.MMSI });
        };
        this.toggleTrack = async () => {
            this.broadcast('ship:track:add', { ship: this.ship });
            this.element('button.toggle-track').blur();
            this.wait = !this.has;
            await this.render();
        };
        this.render = async (ev) => {
            await this.beforeRender();
            this.element(this.replace, true).replaceWith(await this.content(ev));
            await this.afterRender();
            if (!this.init) {
                this.init = true;
                this.broadcast('request:track:mmsi', { MMSI: this.ship.MMSI });
            }
        };
        this.ship = ship;
        this.wait = false;
        this.has = false;
        this.register('click', 'button.toggle-track', this.toggleTrack);
        this.subscribe('ship:track', this.update);
        this.subscribe(`response:track:${this.ship.MMSI}`, this.enable);
    }
    async content(ev) {
        this.has = ev && ev.detail || false;
        return this.toDocumentFragment(`<button
            class="${this.class} toggle-track ${this.classList}${this.has && ' mdc-button--outlined' || ''}"
            aria-label="${this.has && this.textB || this.textA}"${this.wait && ' disabled="disabled"'}>${this.has && this.textB || this.textA}</button>`);
    }
}
exports.TrackButtonView = TrackButtonView;
