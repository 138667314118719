"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const NmeaShipdataFeatureCollection_1 = require("../NmeaShipdataFeature/NmeaShipdataFeatureCollection");
const NmeaPositionFeatureCollection_1 = require("../NmeaPositionFeature/NmeaPositionFeatureCollection");
const Ship_1 = require("./Ship");
class ShipCollection extends NmeaShipdataFeatureCollection_1.NmeaShipdataFeatureCollection {
    constructor(db, logger) {
        super(db, logger);
        this.emitPosition = async (position) => {
            this.emit('position', position);
        };
        this.emitShip = async (ship) => {
            const position = await this.positions.findByMMSI(ship.MMSI, { cache: true, force: false });
            if (position) {
                ship.position = position;
            }
            this.emit('ship', ship);
        };
        this.positions = new NmeaPositionFeatureCollection_1.NmeaPositionFeatureCollection(db, logger);
    }
    unsubscribe() {
        this.off('shipdata', this.emitShip);
        this.positions.off('position', this.emitPosition);
        super.unsubscribe();
        this.positions.unsubscribe();
    }
    async subscribe() {
        await super.subscribe();
        await this.positions.subscribe();
        for (const ship of this) {
            const position = await this.positions.findByMMSI(ship.MMSI, { cache: true, force: false });
            if (position) {
                ship.position = position;
            }
        }
        this.on('shipdata', this.emitShip);
        this.positions.on('position', this.emitPosition);
    }
    model(data) {
        const ship = new Ship_1.Ship(this, data);
        return ship;
    }
    // ***************************************
    // Find
    // ***************************************
    async findByMMSI(mmsi, options) {
        const shipdata = await super.findByMMSI(mmsi, options);
        if (shipdata) {
            let ship = this.collection.find(item => item.MMSI === shipdata.MMSI);
            if (!ship) {
                ship = this.model(shipdata);
            }
            const position = await this.positions.findByMMSI(mmsi, options);
            if (position) {
                ship.position = position;
            }
            else {
                throw new Error(`Position not found, MMSI(${mmsi})`);
            }
            return ship;
        }
        throw new Error(`Shipdata not found, MMSI(${mmsi})`);
    }
    async fetchInterval(sl, sk, el, ek, limit = 0) {
        this._collection = [];
        await this.positions.fetchIntervalAll(sl, sk, el, ek, limit);
        if (!this.positions.isEmpty) {
            await this.fetch({ MMSI: { $in: this.positions.collection.map(position => position.MMSI) } });
            for (const ship of this) {
                const position = await this.positions.findByMMSI(ship.MMSI, { cache: true, force: false });
                if (position) {
                    ship.position = position;
                }
            }
        }
        return this;
    }
    // ***************************************
    // GeoJSON
    // ***************************************
    async toFeatureCollection() {
        const features = [];
        for (const ship of this) {
            try {
                const feature = ship.toFeature();
                features.push(...feature);
            }
            catch (ex) {
                if (ex.message !== 'No Position for Ship') {
                    throw (ex);
                }
            }
        }
        const featureCollection = {
            "type": "FeatureCollection",
            "features": features
        };
        return featureCollection;
    }
}
exports.ShipCollection = ShipCollection;
