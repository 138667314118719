"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionsButtonView = void 0;
const index_1 = require("../lib/index");
class PositionsButtonView extends index_1.AbstractButtonView {
    constructor(selector, ship) {
        super(selector);
        this.name = 'PositionsButtonView';
        this.textA = 'Positions';
        this.textB = 'Positions';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.isShown = false;
        this.positionsShown = (ev) => {
            if (ev.detail.name === 'PositionTableView' && this.ship.MMSI === ev.detail.view.ship.MMSI) {
                this.isShown = true;
                this.render();
            }
        };
        this.positionsHidden = (ev) => {
            if (ev.detail.name === 'PositionTableView' && this.ship.MMSI === ev.detail.view.ship.MMSI) {
                this.isShown = false;
                this.render();
            }
        };
        this.showPositions = () => {
            this.broadcast('ship:positions', { ship: this.ship });
            this.element('button.show-positions').blur();
        };
        this.ship = ship;
        this.register('click', 'button.show-positions', this.showPositions);
        this.subscribe('view:showing', this.positionsShown);
        this.subscribe('view:hidden', this.positionsHidden);
    }
    async content(ev) {
        return this.toDocumentFragment(`<button
            class="${this.class} show-positions ${this.classList}"
            aria-label="${!this.isShown && this.textA || this.textB}"${this.isShown && ' disabled="disabled"'}>${!this.isShown && this.textA || this.textB}</button>`);
    }
}
exports.PositionsButtonView = PositionsButtonView;
