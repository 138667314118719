"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapShowAllFeaturesButtonView = void 0;
const index_1 = require("../lib/index");
class MapShowAllFeaturesButtonView extends index_1.AbstractButtonView {
    constructor(selector, style = 'icon') {
        super(selector);
        this.name = 'MapShowAllFeaturesButtonView';
        this.textA = 'Show all features';
        this.textB = 'All features shown';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.style = 'icon';
        this.showAll = (ev) => {
            ev.preventDefault();
            this.broadcast('map:show:all:features');
            this.element('.show-all').blur();
        };
        this.style = style;
        this.register('click', '.show-all', this.showAll);
        this.subscribe('map:zoom', this.render);
    }
    async content(ev) {
        let has = ev ? ev.detail.allFeaturesShown : true;
        if (this.style !== 'icon') {
            return this.toDocumentFragment(`
                <a class="mdc-list-item ${this.class} show-all" href="#" aria-current="page" tabindex="0"${has && ' disabled="disabled"'}>
                    <i class="material-icons mdc-list-item__graphic" aria-hidden="true">zoom_out_map</i>
                    <span class="mdc-list-item__text">${!has && this.textA || this.textB}</span>
                </a>`);
        }
        return this.toDocumentFragment(`<button
            class="${this.class} show-all ${this.classList}"
            aria-label="${!has && this.textA || this.textB}"${has && ' disabled="disabled"'}>zoom_out_map</button>`);
    }
}
exports.MapShowAllFeaturesButtonView = MapShowAllFeaturesButtonView;
