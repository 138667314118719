"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const NmeaShipdataCollection_1 = require("../NmeaShipdata/NmeaShipdataCollection");
const NmeaShipdataFeature_1 = require("./NmeaShipdataFeature");
class NmeaShipdataFeatureCollection extends NmeaShipdataCollection_1.NmeaShipdataCollection {
    model(data) {
        return new NmeaShipdataFeature_1.NmeaShipdataFeature(this, data);
    }
}
exports.NmeaShipdataFeatureCollection = NmeaShipdataFeatureCollection;
