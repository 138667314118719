"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapZoomOutButtonView = void 0;
const index_1 = require("../lib/index");
class MapZoomOutButtonView extends index_1.AbstractButtonView {
    constructor(selector) {
        super(selector);
        this.name = 'MapZoomOutButtonView';
        this.textA = 'Zoom Out';
        this.textB = 'Min Zoom reached';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.isInit = false;
        this.zoomOut = () => {
            this.broadcast('map:zoom:out');
            this.element('button.zoom-out').blur();
        };
        this.register('click', 'button.zoom-out', this.zoomOut);
        this.subscribe('map:zoom', this.render);
    }
    async content(ev) {
        let can = ev ? ev.detail.canZoomOut : false;
        return this.toDocumentFragment(`<button
            class="${this.class} zoom-out ${this.classList}"
            aria-label="${can && this.textA || this.textB}"${!can && ' disabled="disabled"'}>remove</button>`);
    }
}
exports.MapZoomOutButtonView = MapZoomOutButtonView;
