"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackCollectionLayer = void 0;
const dialog_1 = require("@material/dialog");
const ais_tools_1 = require("ais-tools");
const index_1 = require("../lib/index");
const TrackLayer_1 = require("./TrackLayer");
class TrackCollectionLayer extends index_1.AbstractLayer {
    constructor(mapView) {
        super();
        this.name = 'TrackLayerCollectionView';
        this.selector = '.mdc-dialog';
        this.collection = [];
        this.isEmpty = () => {
            return !this.collection.some(item => item.layer !== undefined);
        };
        this.hasTrackMMSI = (MMSI) => {
            return this.collection.findIndex(item => item.layer && item.layer.ship.MMSI === MMSI) > -1;
        };
        this.addToMap = async (ev) => {
            const ship = ev.detail.ship;
            const MMSI = ship.MMSI;
            if (this.collection.findIndex(item => item.layer && item.layer.ship.MMSI === MMSI) > -1) {
                this.removeFromMap(MMSI);
            }
            else {
                const slot = this.collection.find(item => item.layer === undefined);
                if (slot) {
                    slot.layer = new TrackLayer_1.TrackLayer(ship, this.map);
                    await slot.layer.render(slot.color);
                    this.broadcast('ship:track', { action: 'add', ship: ship });
                }
                else {
                    this.open(true);
                }
            }
        };
        this.listTitle = (isExceed = false) => {
            let title = isExceed ? 'Current Tracks displayed on Map' : 'Maximum number of tracks has been exceeded';
            return this.toDocumentFragment(`<h2 class="mdc-dialog__title">${title}</h2>`);
        };
        this.listSubtitle = (isExceed = false) => {
            let subtitle = isExceed ? 'Select a Track for removal' : 'Remove a track continue.';
            return this.toDocumentFragment(`<p class="mdc-typography--body1">${subtitle}</p>`);
        };
        this.listitemShip = (ship, color) => {
            const html = `<li class="mdc-list-item" tabindex="0" style="background-color: ${color.get(0, 0.4)};">
            <div class="mdc-form-field">
                <div class="mdc-checkbox">
                    <input type="checkbox" class="mdc-checkbox__native-control" data-mmsi="${ship.MMSI}"/>
                    <div class="mdc-checkbox__background">
                        <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                            <path class="mdc-checkbox__checkmark-path" fill="none"
                                d="M1.73,12.91 8.1,19.28 22.79,4.59" />
                        </svg>
                        <div class="mdc-checkbox__mixedmark"></div>
                    </div>
                    <div class="mdc-checkbox__ripple"></div>
                </div>
            </div>
            <span class="mdc-list-item__text unselectable">
                <span class="mdc-list-item__primary-text">${ship.Name}</span>
                <span class="mdc-list-item__secondary-text">${ship.MMSI}</span>
            </span>
        </li>`;
            return this.toDocumentFragment(html);
        };
        this.listitemEmpty = (color) => {
            const html = `<li class="mdc-list-item is-empty" tabindex="0" style="background-color: ${color.get(0, 0.4)};">
            <div class="mdc-form-field">
                <div class="mdc-checkbox">
                    <input type="checkbox" class="mdc-checkbox__native-control" disabled="disabled" />
                    <div class="mdc-checkbox__background">
                        <svg class="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                            <path class="mdc-checkbox__checkmark-path" fill="none"
                                d="M1.73,12.91 8.1,19.28 22.79,4.59" />
                        </svg>
                        <div class="mdc-checkbox__mixedmark"></div>
                    </div>
                    <div class="mdc-checkbox__ripple"></div>
                </div>
            </div>
            <span class="mdc-list-item__text unselectable">
                <span class="mdc-list-item__primary-text">Empty</span>
                <span class="mdc-list-item__secondary-text">Not yet set</span>
            </span>
        </li>`;
            return this.toDocumentFragment(html);
        };
        this.open = (isExceed = false) => {
            this.element('.mdc-dialog__title').replaceWith(this.listTitle(isExceed));
            this.element('.mdc-typography--body1').replaceWith(this.listSubtitle(isExceed));
            for (let item of this.collection) {
                if (typeof (item.layer) !== 'undefined') {
                    this.element('.mdc-list').append(this.listitemShip(item.layer.ship, item.color));
                }
                else {
                    this.element('.mdc-list').append(this.listitemEmpty(item.color));
                }
            }
            this.dialog && this.dialog.open();
        };
        this.close = async () => {
            this.element('.mdc-list').innerHTML = '';
        };
        this.selected = (ev) => {
            if (!ev.target.classList.contains('mdc-checkbox__native-control')) {
                const li = ev.target.closest('li:not([class~="is-empty"])');
                if (li) {
                    const input = li.querySelector('input[type="checkbox"]');
                    input.checked = !input.checked;
                }
            }
        };
        this.accept = async () => {
            const checked = document.querySelectorAll('input:checked');
            for (const item of checked) {
                this.removeFromMap(Number(item.dataset.mmsi));
            }
            this.dialog && this.dialog.close();
        };
        this.body = () => {
            const html = `<div class="mdc-dialog__container">
            <div class="mdc-dialog__surface" role="alertdialog">
                <h2 class="mdc-dialog__title"></h2>
                <div class="mdc-dialog__content">
                    <p class="mdc-typography--body1"></p>
                    <ul class="mdc-list mdc-list--two-line"></ul>
                </div>
                <div class="mdc-dialog__actions">
                    <button type="button" class="mdc-button mdc-dialog__button" data-mdc-dialog-action="close">
                        <div class="mdc-button__ripple"></div>
                        <span class="mdc-button__label">Cancel</span>
                    </button>
                    <button type="button" class="mdc-button mdc-dialog__button btn-accept" data-mdc-dialog-action="accept">
                        <div class="mdc-button__ripple"></div>
                        <span class="mdc-button__label">Remove selected</span>
                    </button>
                </div>
            </div>
            </div>
            <div class="mdc-dialog__scrim"></div>`;
            return this.toDocumentFragment(html);
        };
        this.render = async () => {
            await this.beforeRender();
            await this.content();
            this.dialog = new dialog_1.MDCDialog(this.el);
            await this.afterRender();
        };
        this.map = mapView;
        for (const color of ais_tools_1.ColorSchemes) {
            this.collection.push({
                color,
                layer: undefined
            });
        }
    }
    removeFromMap(ship) {
        const MMSI = (typeof (ship) === 'number') ? ship : ship.MMSI;
        const slot = this.collection.find(item => item.layer && item.layer.ship.MMSI === MMSI);
        if (slot) {
            const ship = slot.layer.ship;
            slot.layer.remove();
            slot.layer = undefined;
            this.broadcast('ship:track', { action: 'removed', ship: ship });
        }
    }
    async remove() {
        this.dialog && this.dialog.close();
        for (const slot of this.collection) {
            if (slot.layer) {
                this.removeFromMap(slot.layer.ship.MMSI);
            }
        }
        super.remove();
    }
    attachEvents() {
        this.dialog && this.dialog.listen('MDCDialog:closing', this.close);
        return super.attachEvents();
    }
    detachEvents() {
        this.dialog && this.dialog.unlisten('MDCDialog:closing', this.close);
        return super.detachEvents();
    }
    async content() {
        this.el.innerHTML = '';
        this.el.prepend(this.body());
        this.subscribe('track:collection:panel', this.open);
        this.subscribe('ship:track:add', this.addToMap);
        this.register('click', '.mdc-list', this.selected);
        this.register('click', 'button.btn-accept', this.accept);
    }
}
exports.TrackCollectionLayer = TrackCollectionLayer;
