"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowDrawerButtonView = void 0;
const index_1 = require("../lib/index");
class ShowDrawerButtonView extends index_1.AbstractButtonView {
    constructor(selector) {
        super(selector);
        this.name = 'ShowDrawerButtonView';
        this.textA = 'Show menu';
        this.textB = 'Menu shown';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.zoomIn = () => {
            this.broadcast('menu:clicked');
            this.element('button.drawer-show').blur();
        };
        this.register('click', 'button.drawer-show', this.zoomIn);
    }
    async content(ev) {
        return this.toDocumentFragment(`<button
            class="${this.class} drawer-show ${this.classList}"
            aria-label="${this.textA}">menu</button>`);
    }
}
exports.ShowDrawerButtonView = ShowDrawerButtonView;
