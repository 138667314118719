"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopupCollectionLayer = void 0;
const ais_tools_1 = require("ais-tools");
const index_1 = require("../lib/index");
const index_2 = require("../views/index");
class PopupCollectionLayer extends index_1.AbstractLayer {
    constructor(collection, map) {
        super();
        this.name = 'ship';
        this.selector = 'ships';
        this.popups = [];
        this.addPositionPopup = async (ev) => {
            const position = ev.detail.position;
            const popup = this.popups.find(popup => popup.popup.position && popup.popup.position._id === position._id);
            if (popup) {
                return this.removePopup(popup);
            }
            else {
                const popup = new index_2.PositionPopupView(position);
                const content = await popup.render();
                content.setLatLng({
                    lat: position.Latitude,
                    lng: position.Longitude
                });
                this.map.openPopup(content);
                this.popups.push({ popup, content });
            }
        };
        this.addShipPopup = async (ev) => {
            const ship = ev.detail.ship;
            const popup = this.popups.find(popup => popup.popup.ship && popup.popup.ship.MMSI === ship.MMSI);
            if (popup) {
                return this.removePopup(popup);
            }
            else {
                const popup = new index_2.ShipPopupView(ship);
                const content = await popup.render();
                content.setLatLng(this.getLocation(ship));
                this.map.openPopup(content);
                this.popups.push({ popup, content });
            }
        };
        this.removePopup = (popup, updateCollection = true) => {
            if (updateCollection) {
                if (popup.popup.ship) {
                    const idx = this.popups.findIndex(item => item.popup.ship && item.popup.ship.MMSI === popup.popup.ship.MMSI);
                    this.popups.splice(idx, 1);
                }
                else if (popup.popup.position) {
                    const idx = this.popups.findIndex(item => item.popup.poition && item.popup.poition._id === popup.popup.position._id);
                    this.popups.splice(idx, 1);
                }
            }
            this.map.closePopup(popup.content);
            popup.popup.remove();
        };
        this.removePopups = (ev) => {
            for (const popup of this.popups) {
                this.removePopup(popup, false);
            }
            this.popups = [];
        };
        this.updatePopup = (popup, ship) => {
            popup.content.setLatLng(this.getLocation(ship));
        };
        this.updatePopups = async (data) => {
            try {
                let ship;
                if (data instanceof ais_tools_1.NmeaPositionFeature) {
                    ship = await this.collection.findByMMSI(data.MMSI, { cache: true, force: false });
                }
                else {
                    ship = data;
                }
                const popup = this.popups.find(popup => popup.popup.ship && popup.popup.ship.MMSI === data.MMSI);
                if (popup) {
                    this.updatePopup(popup, ship);
                }
            }
            catch (ex) { }
        };
        this.collection = collection;
        this.map = map;
    }
    getLocation(ship) {
        const feature = ship.toShipFeature(ship.position);
        const geometry = feature[0].geometry;
        const y = geometry.coordinates[0].map(x => x[0]);
        const x = geometry.coordinates[0].map(x => x[1]);
        const cx = (Math.min(...x) + Math.max(...x)) / 2;
        const cy = (Math.min(...y) + Math.max(...y)) / 2;
        return {
            lat: cx,
            lng: cy
        };
    }
    attachEvents() {
        if (this.collection) {
            this.collection.on('ship', this.updatePopups);
            this.collection.on('position', this.updatePopups);
        }
        return super.attachEvents();
    }
    detachEvents() {
        if (this.collection) {
            this.collection.off('ship', this.updatePopups);
            this.collection.off('position', this.updatePopups);
        }
        return super.detachEvents();
    }
    async remove() {
        this.removePopups();
        super.remove();
    }
    async content() {
        this.subscribe('ship:clicked', this.addShipPopup);
        this.subscribe('track:clicked', this.addPositionPopup);
        this.subscribe('map:clicked', this.removePopups);
    }
}
exports.PopupCollectionLayer = PopupCollectionLayer;
