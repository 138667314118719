"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginView = void 0;
const index_1 = require("../lib/index");
const lib_1 = require("../lib");
const buttons_1 = require("../buttons");
const textfield_1 = require("@material/textfield");
const linear_progress_1 = require("@material/linear-progress");
class LoginView extends index_1.AbstractView {
    constructor() {
        super();
        this.name = 'Login';
        this.selector = '.dialog.login';
        this.validate = () => {
            var _a, _b;
            this.element('.message').classList.remove('error', 'success');
            if (((_a = this.usernameField) === null || _a === void 0 ? void 0 : _a.valid) && ((_b = this.passwordField) === null || _b === void 0 ? void 0 : _b.valid)) {
                this.get('accept').disable = false;
            }
            else {
                this.get('accept').disable = true;
            }
        };
        this.show = async () => {
            this.el.style.display = 'block';
            await this._show('zoomIn', this.element('.login-container'));
        };
        this.hide = async () => {
            await this._hide('zoomOut', this.element('.login-container'));
            this.el.style.display = 'none';
        };
        this.close = async (timed) => {
            if (timed && this.progressBar) {
                const p = this.progressBar;
                let h;
                let s = 0.1;
                h = setInterval(() => {
                    p.progress = s;
                    s += 0.1;
                }, 100);
                await new Promise(resolve => setTimeout(resolve, 1000));
                clearInterval(h);
            }
            await this.remove();
            this.el.innerHTML = '';
        };
        this.accept = async () => {
            var _a, _b;
            this.element('.mdc-linear-progress').classList.add('mdc-linear-progress--indeterminate');
            this.element('.mdc-text-field__input').setAttribute('disabled', 'disabled');
            this.get('close').disable = true;
            this.get('accept').disable = true;
            this.element('.mdc-linear-progress').classList.remove('mdc-linear-progress--indeterminate');
            const res = await lib_1.auth.login((_a = this.usernameField) === null || _a === void 0 ? void 0 : _a.value, (_b = this.passwordField) === null || _b === void 0 ? void 0 : _b.value);
            if (res.ok) {
                this.element('.message').innerHTML = 'Login successful';
                this.element('.message').classList.add('success');
                this.close(true);
            }
            else {
                const message = await res.text();
                this.element('.message').innerHTML = message;
                this.element('.message').classList.add('error');
                this.element('.mdc-text-field__input').removeAttribute('disabled');
                this.get('close').disable = false;
            }
        };
        this.body = () => {
            let html = [];
            html.push(`<div class="login-container">`);
            html.push(`<div role="progressbar" class="mdc-linear-progress" aria-valuemin="0"
                aria-valuemax="1" aria-valuenow="0">
                <div class="mdc-linear-progress__buffer"></div>
                <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                </div>
                <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                </div>
            </div>`);
            html.push(`<div class="form">`);
            html.push(`<h2 class="mdc-dialog__title">Login</h2>`);
            html.push(`<ul class="mdc-list mdc-list--non-interactive">`);
            html.push(`<li class="mdc-list-item">`);
            html.push(`<div class="mdc-text-field mdc-text-field--outlined username-field">
                <input class="mdc-text-field__input" size="40" name="username" type="email" id="username" required="true" minLength="8">
                <div class="mdc-notched-outline">
                <div class="mdc-notched-outline__leading"></div>
                <div class="mdc-notched-outline__notch">
                <label for="username" class="mdc-floating-label">Username</label>
                </div>
                <div class="mdc-notched-outline__trailing"></div>
                </div>
            </div>`);
            html.push(`</li>`);
            html.push(`<li class="mdc-list-item">`);
            html.push(`<div class="mdc-text-field mdc-text-field--outlined password-field">
                <input class="mdc-text-field__input" size="40" name="password" type="password" id="password" required="true" minLength="8">
                <div class="mdc-notched-outline">
                <div class="mdc-notched-outline__leading"></div>
                <div class="mdc-notched-outline__notch">
                <label for="password" class="mdc-floating-label">Password</label>
                </div>
                <div class="mdc-notched-outline__trailing"></div>
                </div>
            </div>`);
            html.push(`</li>`);
            html.push(`</ul>`);
            html.push(`<p class="mdc-typography--body1 message"></p>`);
            html.push(`</div>`);
            html.push(`<div class="mdc-dialog__actions">`);
            html.push(this.get('close').preRender());
            html.push(this.get('accept').preRender());
            html.push(`</div>`);
            html.push(`</div>`);
            return this.toDocumentFragment(html);
        };
        this.add('close', new buttons_1.CloseButtonView(this.selector, this));
        this.add('accept', new buttons_1.AcceptButtonView(this.selector, 'Login', this));
        this.register('change', '#username', this.validate);
        this.register('change', '#password', this.validate);
        this.register('keyup', '#username', this.validate);
        this.register('keyup', '#password', this.validate);
    }
    async render(ev) {
        await this.beforeRender();
        await this.content();
        this.usernameField = new textfield_1.MDCTextField(this.element('.username-field'));
        this.usernameField.useNativeValidation = true;
        this.passwordField = new textfield_1.MDCTextField(this.element('.password-field'));
        this.passwordField.useNativeValidation = true;
        this.progressBar = new linear_progress_1.MDCLinearProgress(this.element('.mdc-linear-progress'));
        this.get('accept').disable = true;
        await this.afterRender();
    }
    async content() {
        this.el.innerHTML = '';
        this.el.prepend(this.body());
    }
}
exports.LoginView = LoginView;
