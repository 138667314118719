"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelpIconButtonView = void 0;
const index_1 = require("../lib/index");
class HelpIconButtonView extends index_1.AbstractButtonView {
    constructor(selector) {
        super(selector);
        this.name = 'HelpIconButtonView';
        this.textA = 'Show Help';
        this.textB = 'Hide Help';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.init = false;
        this.shown = false;
        this.update = (ev) => {
            this.shown = ev.detail;
            this.render();
        };
        this.showHelp = () => {
            this.broadcast('request:show:help');
            this.element('button.help-btn').blur();
        };
        this.render = async () => {
            await this.beforeRender();
            this.element(this.replace, true).replaceWith(await this.content());
            await this.afterRender(false);
            if (!this.init) {
                this.init = true;
                this.broadcast('request:help:shown');
            }
        };
        this.init = false;
        this.register('click', 'button.help-btn', this.showHelp);
        this.subscribe(`response:help:shown`, this.update);
    }
    async content() {
        let has = this.shown;
        return this.toDocumentFragment(`<button
            class="${this.class} help-btn ${this.classList}"${has && ' disabled="disabled"'}
            aria-label="${has && this.textB || this.textA}">help</button>`);
    }
}
exports.HelpIconButtonView = HelpIconButtonView;
