"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipTableBookmarksButtonView = void 0;
const lib_1 = require("../lib");
class ShipTableBookmarksButtonView extends lib_1.AbstractButtonView {
    constructor(selector) {
        super(selector);
        this.name = 'ShipTableBookmarksButtonView';
        this.textA = 'Filter HADAG Ferries';
        this.textB = 'Filtered by HADAG Ferries';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.init = false;
        this._filter = '211437250,211437260,211437300,211437160,211437240,211437190,211437280,211437330,211257820,211263850,211437270,211316150,211437470,211437320,211437290,211151080,211437340,211437770,211462080,211472760,211611680,211643790,211437180,211437310,211762670,211231260';
        this.filter = async () => {
            this.broadcast('ships:filter', { filter: this._filter });
            this.element('button.filter').blur();
        };
        this.register('click', 'button.filter', this.filter);
        this.subscribe('ships:filtered', this.render);
    }
    async content(ev) {
        let can = ev && ev.detail.filter === this._filter ? false : true;
        return this.toDocumentFragment(`<button
            class="${this.class} filter ${this.classList}"
            aria-label="${can && this.textA || this.textB}"${!can && ' disabled="disabled"'}>directions_boat</button>`);
    }
}
exports.ShipTableBookmarksButtonView = ShipTableBookmarksButtonView;
