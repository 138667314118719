"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AbstractDatabase {
    connect() {
        throw new Error("Method not implemented.");
    }
    close() {
        throw new Error("Method not implemented.");
    }
    createObjectID() {
        throw new Error("Method not implemented.");
    }
    create(colName, data) {
        throw new Error("Method not implemented.");
    }
    update(colName, data) {
        throw new Error("Method not implemented.");
    }
    findOne(colName, filter) {
        throw new Error("Method not implemented.");
    }
    findAll(colName, filter, limit, options) {
        throw new Error("Method not implemented.");
    }
    tail(name, filter) {
        throw new Error("Method not implemented.");
    }
}
exports.AbstractDatabase = AbstractDatabase;
