"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AbstractNmeaCollection_1 = require("./AbstractNmeaCollection");
class AbstractNmeaIterableCollection extends AbstractNmeaCollection_1.AbstractNmeaCollection {
    constructor() {
        // ***************************************
        // Iterator
        // ***************************************
        super(...arguments);
        this._collection = [];
        this.cnt = 0;
        this._subscribe = async (data) => {
            await this.onSubscription(data);
        };
    }
    comperator(a, b) {
        return b.TimeStamp.getTime() - a.TimeStamp.getTime();
    }
    sort() {
        return this._collection.sort(this.comperator);
    }
    get isEmpty() {
        return this._collection.length > 0 ? false : true;
    }
    get length() {
        return this._collection.length;
    }
    get collection() {
        return this._collection;
    }
    first() {
        if (!this.isEmpty) {
            return this._collection[0];
        }
    }
    last() {
        if (this._collection.length) {
            return this._collection[this._collection.length - 1];
        }
    }
    [Symbol.iterator]() {
        this.cnt = 0;
        return {
            next: () => {
                return {
                    done: this._collection.length === 0 || this.cnt === this._collection.length,
                    value: this._collection[this.cnt++]
                };
            }
        };
    }
    async delay(delay = 1000) {
        return new Promise(resolve => {
            setTimeout(resolve, delay);
        });
    }
    unsubscribe() {
        this.messages && this.messages.off('data', this._subscribe);
    }
    async subscribe() {
        this.isSubscription = true;
        const tquery = {
            'Type': this.typeSubscription,
            'TimeStamp': { $gte: Date.now() }
        };
        this.messages = this.database.tail('messages', tquery);
        this.messages.on('data', this._subscribe);
    }
    // ***************************************
    // Reporting
    // ***************************************
    warn(message, current) {
        if (this.logger.level < 1) {
            return;
        }
        if (!current) {
            this.logger.warn(message);
            return;
        }
        this.logger.warn(message, current && current.toInfo());
    }
    info(message, current, previous) {
        if (this.logger.level === 4) {
            this.logger.verbose(message, current && current.toInfo());
        }
        if (this.logger.level === 5) {
            this.logger.debug(message, current && current.toReport());
        }
        if (this.logger.level === 6) {
            if (previous) {
                this.logger.silly(message, current && current.toReportDiff(previous));
            }
            else {
                this.logger.silly(message, current && current.toReport());
            }
        }
    }
}
exports.AbstractNmeaIterableCollection = AbstractNmeaIterableCollection;
