"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageLogger = void 0;
class MessageLogger {
    constructor() {
        this.level = 0;
        this.level = 1;
    }
    error(message, opt) {
        if (opt) {
            console.log(message, opt.join('\n'));
        }
        else {
            console.log(message);
        }
    }
    warn(message, opt) {
        if (this.level > 0) {
            if (opt) {
                console.log(message, opt.join('\n'));
            }
            else {
                console.log(message);
            }
        }
    }
    info(message, opt) {
        if (this.level === 2) {
            if (opt) {
                console.log(message, opt.join('\n'));
            }
            else {
                console.log(message);
            }
        }
    }
    debug(message, opt) { }
    verbose(message, opt) { }
    silly(message, opt) { }
}
exports.MessageLogger = MessageLogger;
