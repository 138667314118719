"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipTabButtonView = void 0;
const index_1 = require("../lib/index");
class ShipTabButtonView extends index_1.AbstractButtonView {
    constructor(selector, ship) {
        super(selector);
        this.name = 'ShipTabButtonView';
        this.textA = 'Ship Data';
        this.textB = 'Ship Data';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.isShown = true;
        this.dataShown = (ev) => {
            this.isShown = true;
            this.render();
        };
        this.dataHidden = (ev) => {
            this.isShown = false;
            this.render();
        };
        this.showShipdata = () => {
            this.broadcast('ship:details:data', { ship: this.ship });
            this.element('button.show-data').blur();
        };
        this.ship = ship;
        this.register('click', 'button.show-data', this.showShipdata);
        this.subscribe('ship:data:shown', this.dataShown);
        this.subscribe('ship:data:hidden', this.dataHidden);
    }
    async content(ev) {
        return this.toDocumentFragment(`<button
            class="${this.class} show-data ${this.classList}"
            aria-label="${!this.isShown && this.textA || this.textB}"${this.isShown && ' disabled="disabled"'}>${!this.isShown && this.textA || this.textB}</button>`);
    }
}
exports.ShipTabButtonView = ShipTabButtonView;
