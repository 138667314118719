"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const RGBColor_1 = require("./RGBColor");
const Color_1 = require("./Color");
exports.rgba = (r, g, b, a) => {
    return new RGBColor_1.RGBColor(r, g, b, a);
};
class Colors {
    constructor() {
        this.colors = [];
        this.cnt = 0;
    }
    get length() {
        return this.colors.length;
    }
    [Symbol.iterator]() {
        this.cnt = 0;
        return {
            next: () => {
                return {
                    done: this.colors.length === 0 || this.cnt === this.colors.length,
                    value: this.colors[this.cnt++]
                };
            }
        };
    }
    add(color) {
        const idx = this.colors.findIndex(item => item.name === color.name);
        if (idx > -1) {
            this.colors.splice(idx, 1, color);
        }
        else {
            this.colors.push(color);
        }
    }
    get(name, level) {
        if (typeof (name) === 'string') {
            const c = this.colors.find(color => color.name === name);
            if (c) {
                if (!level) {
                    return c;
                }
                return c.get(level);
            }
        }
        else if (typeof (name) === 'number') {
            const d = this.colors[name];
            if (d) {
                return d;
            }
        }
        throw new Error('Color not found');
    }
    random() {
        const idx1 = Math.floor(Math.random() * Math.floor(this.length));
        const idx2 = Math.floor(Math.random() * Math.floor(5));
        return this.colors[idx1].get(idx2);
    }
}
exports.Colors = Colors;
const blueColor = new Color_1.Color('blue');
blueColor.add('.rgba-primary-0', { color: exports.rgba(106, 152, 206, 1) });
blueColor.add('.rgba-primary-1', { color: exports.rgba(200, 221, 245, 1) });
blueColor.add('.rgba-primary-2', { color: exports.rgba(149, 185, 228, 1) });
blueColor.add('.rgba-primary-3', { color: exports.rgba(72, 121, 180, 1) });
blueColor.add('.rgba-primary-4', { color: exports.rgba(44, 96, 158, 1) });
const greenColor = new Color_1.Color('green');
greenColor.add('.rgba-primary-0', { color: exports.rgba(99, 210, 163, 1) });
greenColor.add('.rgba-primary-1', { color: exports.rgba(197, 246, 225, 1) });
greenColor.add('.rgba-primary-2', { color: exports.rgba(143, 230, 193, 1) });
greenColor.add('.rgba-primary-3', { color: exports.rgba(64, 187, 134, 1) });
greenColor.add('.rgba-primary-4', { color: exports.rgba(36, 166, 110, 1) });
const violetColor = new Color_1.Color('violet');
violetColor.add('.rgba-primary-0', { color: exports.rgba(176, 103, 207, 1) });
violetColor.add('.rgba-primary-1', { color: exports.rgba(231, 198, 246, 1) });
violetColor.add('.rgba-primary-2', { color: exports.rgba(204, 146, 229, 1) });
violetColor.add('.rgba-primary-3', { color: exports.rgba(148, 68, 183, 1) });
violetColor.add('.rgba-primary-4', { color: exports.rgba(124, 40, 161, 1) });
const redColor = new Color_1.Color('red');
redColor.add('.rgba-primary-0', { color: exports.rgba(255, 120, 120, 1) });
redColor.add('.rgba-primary-1', { color: exports.rgba(255, 204, 204, 1) });
redColor.add('.rgba-primary-2', { color: exports.rgba(255, 159, 159, 1) });
redColor.add('.rgba-primary-3', { color: exports.rgba(255, 87, 87, 1) });
redColor.add('.rgba-primary-4', { color: exports.rgba(241, 52, 52, 1) });
const pinkColor = new Color_1.Color('pink');
pinkColor.add('.rgba-primary-0', { color: exports.rgba(229, 108, 172, 1) });
pinkColor.add('.rgba-primary-1', { color: exports.rgba(250, 200, 226, 1) });
pinkColor.add('.rgba-primary-2', { color: exports.rgba(241, 150, 198, 1) });
pinkColor.add('.rgba-primary-3', { color: exports.rgba(215, 74, 148, 1) });
pinkColor.add('.rgba-primary-4', { color: exports.rgba(197, 42, 124, 1) });
const orangeColor = new Color_1.Color('orange');
orangeColor.add('.rgba-primary-0', { color: exports.rgba(255, 197, 120, 1) });
orangeColor.add('.rgba-primary-1', { color: exports.rgba(255, 233, 204, 1) });
orangeColor.add('.rgba-primary-2', { color: exports.rgba(255, 214, 159, 1) });
orangeColor.add('.rgba-primary-3', { color: exports.rgba(255, 183, 87, 1) });
orangeColor.add('.rgba-primary-4', { color: exports.rgba(241, 160, 52, 1) });
const yellowColor = new Color_1.Color('yellow');
yellowColor.add('.rgba-primary-0', { color: exports.rgba(255, 222, 120, 1) });
yellowColor.add('.rgba-primary-1', { color: exports.rgba(255, 242, 204, 1) });
yellowColor.add('.rgba-primary-2', { color: exports.rgba(255, 231, 159, 1) });
yellowColor.add('.rgba-primary-3', { color: exports.rgba(255, 214, 87, 1) });
yellowColor.add('.rgba-primary-4', { color: exports.rgba(241, 195, 52, 1) });
const lightgreenColor = new Color_1.Color('lightgreen');
lightgreenColor.add('.rgba-primary-0', { color: exports.rgba(114, 228, 108, 1) });
lightgreenColor.add('.rgba-primary-1', { color: exports.rgba(202, 250, 199, 1) });
lightgreenColor.add('.rgba-primary-2', { color: exports.rgba(154, 240, 149, 1) });
lightgreenColor.add('.rgba-primary-3', { color: exports.rgba(80, 214, 73, 1) });
lightgreenColor.add('.rgba-primary-4', { color: exports.rgba(50, 196, 42, 1) });
exports.ColorSchemes = new Colors();
exports.ColorSchemes.add(blueColor);
exports.ColorSchemes.add(greenColor);
exports.ColorSchemes.add(redColor);
exports.ColorSchemes.add(violetColor);
exports.ColorSchemes.add(pinkColor);
exports.ColorSchemes.add(orangeColor);
exports.ColorSchemes.add(lightgreenColor);
exports.ColorSchemes.add(yellowColor);
