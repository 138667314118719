"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractView = void 0;
const events_1 = require("events");
class AbstractView extends events_1.EventEmitter {
    constructor() {
        super(...arguments);
        this.el = document.createElement('div');
        this.isDestroying = false;
        this.isDestoryed = true;
        this.isRendered = false;
        this.isAttached = false;
        this.isShown = false;
        this.elements = [];
        this.events = [];
        this.subscriptions = [];
        this.subViews = [];
        this.animationSpeed = 'faster';
        this.close = async () => { };
        this.accept = async () => { };
        this.refresh = async () => { };
        this._show = async (animationName, _el) => {
            const el = _el || this.el;
            if (!el)
                throw new Error('No Element');
            this.broadcast('view:showing');
            if (typeof (animationName) === 'undefined') {
                el.style.display = 'block';
                this.broadcast('view:shown');
                this.isShown = true;
                return;
            }
            return new Promise(resolve => {
                el.addEventListener('animationend', () => {
                    el.classList.remove('animated', animationName, this.animationSpeed);
                    resolve();
                    this.broadcast('view:shown');
                    this.isShown = true;
                }, { once: true });
                el.classList.add('animated', animationName, this.animationSpeed);
                el.style.display = 'block';
            });
        };
        this._hide = async (animationName, _el) => {
            const el = _el || this.el;
            if (!el)
                throw new Error('No Element');
            this.broadcast('view:hiding');
            if (typeof (animationName) === 'undefined') {
                el.style.display = 'none';
                this.broadcast('view:hidden');
                this.isShown = false;
                return;
            }
            return new Promise(resolve => {
                el.addEventListener('animationend', () => {
                    el.style.display = 'none';
                    el.classList.remove('animated', animationName, this.animationSpeed);
                    resolve();
                    this.broadcast('view:hidden');
                    this.isShown = false;
                }, { once: true });
                el.classList.add('animated', animationName, this.animationSpeed);
            });
        };
    }
    add(name, view) {
        this.subViews.push({ name, view });
    }
    get(name) {
        try {
            return this.subViews.find(item => item.name === name).view;
        }
        catch (ex) {
            console.log(name, this.subViews);
            console.trace(ex);
        }
    }
    async hide() {
        await this._hide();
    }
    async show() {
        await this._show();
    }
    register(event, selector, callback) {
        if (typeof (selector) === 'string') {
            this.events.push({
                selector: selector,
                event: event,
                callback: callback
            });
        }
        else {
            this.events.push({
                event: event,
                callback: selector
            });
        }
    }
    unsubscribe(event) {
        const idx = this.subscriptions.findIndex(subscription => subscription.event === event);
        if (idx > -1) {
            this.subscriptions.splice(idx, 1);
        }
    }
    subscribe(event, callback) {
        this.subscriptions.push({ event, callback });
    }
    broadcast(name, data = {}) {
        const eventData = Object.assign(data, {
            name: this.name,
            view: this
        });
        document.dispatchEvent(new CustomEvent(name, { detail: eventData }));
    }
    async delay(delay = 1000) {
        return new Promise(resolve => {
            setTimeout(resolve, delay);
        });
    }
    element(selector, force = false) {
        if (this.isDestroying) {
            throw new Error('View is isDestroying');
        }
        const el = document.querySelector(this.selector);
        if (el) {
            if (force) {
                const idx = this.elements.findIndex(element => element.selector === selector);
                if (idx > -1) {
                    this.elements.splice(idx, 1);
                }
            }
            let res = this.elements.find(element => element.selector === selector);
            if (!res) {
                const _el = el.querySelector(selector);
                if (_el) {
                    res = {
                        selector,
                        el: _el
                    };
                    this.elements.push(res);
                }
                else {
                    // if (this.name !== 'ShipTableView') {
                    //     console.log('-----------------------')
                    //     console.log(this.name)
                    //     console.log('isDestroying', this.isDestroying)
                    //     console.log('isDestoryed', this.isDestoryed)
                    //     console.log('isRendered', this.isRendered)
                    //     console.log('isAttached', this.isAttached)
                    //     console.log('isShown', this.isShown)
                    // }
                    // if (this.name !== 'ShipImageView' && this.name !== 'ShipTableView') console.log(this)
                    throw new Error('No Element found ' + this.selector + ' ' + selector);
                }
            }
            return res.el;
        }
        else {
            // console.log('-----------------------')
            // console.log(this.name)
            // console.log('isDestroying', this.isDestroying)
            // console.log('isDestoryed', this.isDestoryed)
            // console.log('isRendered', this.isRendered)
            // console.log('isAttached', this.isAttached)
            // console.log('isShown', this.isShown)
            throw new Error('No Main Element ' + this.selector);
        }
    }
    attachEvents() {
        if (!this.isAttached) {
            for (const event of this.events) {
                if (event.selector) {
                    event.el = this.element(event.selector, true);
                }
                else {
                    event.el = this.el;
                }
                event.el.addEventListener(event.event, event.callback, false);
            }
            for (const subscription of this.subscriptions) {
                document.addEventListener(subscription.event, subscription.callback, false);
            }
            this.isAttached = true;
        }
        return this.isAttached;
    }
    detachEvents() {
        if (this.isAttached) {
            for (const event of this.events) {
                event.el.removeEventListener(event.event, event.callback, false);
            }
            for (const subscription of this.subscriptions) {
                document.removeEventListener(subscription.event, subscription.callback, false);
            }
            this.isAttached = false;
        }
        return this.isAttached;
    }
    async remove() {
        if (this.isDestoryed) {
            return;
        }
        await this.hide();
        this.isDestroying = true;
        this.detachEvents();
        this.events = [];
        this.subscriptions = [];
        this.isDestroying = false;
        this.isDestoryed = true;
        this.isRendered = false;
        for (const subView of this.subViews) {
            subView.view.remove();
        }
        this.broadcast('view:remove');
    }
    async beforeRender() {
        this.broadcast('view:render:before');
        this.detachEvents();
        this.elements = [];
        this.el = document.querySelector(this.selector);
    }
    async afterRender(autoshow = true) {
        try {
            for (const subView of this.subViews) {
                subView.view.render();
            }
            this.attachEvents();
        }
        catch (ex) {
            console.log(ex.message);
        }
        this.isDestoryed = false;
        this.isRendered = true;
        if (autoshow) {
            await this.show();
        }
        this.broadcast('view:render:after');
    }
    async render(ev) {
        await this.beforeRender();
        await this.content(ev);
        await this.afterRender();
    }
    toDocumentFragment(html) {
        if (Array.isArray(html)) {
            html = html.join('');
        }
        const template = document.createElement('template');
        template.innerHTML = html;
        return template.content;
    }
}
exports.AbstractView = AbstractView;
