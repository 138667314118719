"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RGBColor {
    constructor(r, g, b, a) {
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
    }
    toString(opacity = 1) {
        return `rgba(${this.r},${this.g},${this.b},${opacity})`;
    }
}
exports.RGBColor = RGBColor;
