"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionTabButtonView = void 0;
const index_1 = require("../lib/index");
class PositionTabButtonView extends index_1.AbstractButtonView {
    constructor(selector, ship) {
        super(selector);
        this.name = 'PositionTabButtonView';
        this.textA = 'Position';
        this.textB = 'Position';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.isShown = false;
        this.positionShown = (ev) => {
            this.isShown = true;
            this.render();
        };
        this.positionHidden = (ev) => {
            this.isShown = false;
            this.render();
        };
        this.showShipdata = () => {
            this.broadcast('ship:details:position', { ship: this.ship });
            this.element('button.show-position').blur();
        };
        this.ship = ship;
        this.register('click', 'button.show-position', this.showShipdata);
        this.subscribe('ship:position:shown', this.positionShown);
        this.subscribe('ship:position:hidden', this.positionHidden);
    }
    async content(ev) {
        return this.toDocumentFragment(`<button
            class="${this.class} show-position ${this.classList}"
            aria-label="${!this.isShown && this.textA || this.textB}"${this.isShown && ' disabled="disabled"'}>${!this.isShown && this.textA || this.textB}</button>`);
    }
}
exports.PositionTabButtonView = PositionTabButtonView;
