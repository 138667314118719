"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelpView = void 0;
const lib_1 = require("../lib");
const buttons_1 = require("../buttons");
class HelpView extends lib_1.AbstractView {
    constructor() {
        super();
        this.name = 'HelpView';
        this.selector = '.dialog.help';
        this.presentations = [
            'https://docs.google.com/presentation/d/e/2PACX-1vTvjf7zjghay-K6lWfSazIewxhZcLYGdy4qU-Y2HUdvJtSvQyXsw3zcPcMReHmacuN6wrX7loEg4NCw/embed?start=true&loop=true&rm=minimal',
            'https://docs.google.com/presentation/d/e/2PACX-1vRbO1PU2a-fzAenxPsH9eG8sCzevo_ubX39yiIwmBbpR8SjWOaYUmM0xobbzraBq-uwrpl9YVpFBFj1/embed?start=true&loop=true&rm=minimal',
            'https://docs.google.com/presentation/d/e/2PACX-1vQL17eQze-N9sKs-8YP--P5d6voeVk1E4ga4ZqP-1sjEDaQImbkfWtdDm4yIAbjQabf4IBkDOGM8L6X/embed?start=true&loop=true&rm=minimal',
            'https://docs.google.com/presentation/d/e/2PACX-1vTHSNClA0SEYFeVIsu5UvCFAxJ8irznJY-Qrr7tXnkmWrjQLBsN3oKp07mWYPHi_Aq-sOnockImiB62/embed?start=true&loop=true&rm=minimal',
            'https://docs.google.com/presentation/d/e/2PACX-1vTapn9WIzkBczRigpWkjGQAWFUZCkhIb7wezd_j_eVMJflfbIvGDXQgl-MricpRao931wdZZjz_1LOo/embed?start=true&loop=true&rm=minimal',
            'https://docs.google.com/presentation/d/e/2PACX-1vRVAKVYaN0zolqX3lAhfx4hDQIBAWpKLOtVNe6AxzBL3U4Hoi3s2B0SUeyxk93F77SqwiQRDztiZSCA/embed?start=true&loop=true&rm=minimal',
            'https://docs.google.com/presentation/d/e/2PACX-1vQt_Dt4_YD81Y2lM-u3q3VEPXmeeHXhEa6hOo2lQqfVa9dzHiUuEziRS9Wul_mPfzOcZMEKAkvEJegN/embed?start=true&loop=true&rm=minimal',
            'https://docs.google.com/presentation/d/e/2PACX-1vToUy308GT61i8oHNfoZv85Ttug2VkMxi3D-HTG-SRsMlfQ5zMos7jXGGTSgmfXgarRvFHqFruuNqgI/embed?start=true&loop=true&rm=minimal'
        ];
        this.timeouts = [
            10000, 18000, 23000, 20000, 20000, 18000, 22000, 22000
        ];
        this.frame = 0;
        this.interrupt = false;
        this.clicked = false;
        this.slideSpeed = 'faster';
        this.hideSlide = async (el) => {
            return new Promise(resolve => {
                el.addEventListener('animationend', () => {
                    el.style.display = 'none';
                    el.classList.remove('animated', 'fadeOut', this.slideSpeed);
                    el.src = el.src;
                    resolve();
                }, { once: true });
                el.classList.add('animated', 'fadeOut', this.slideSpeed);
            });
        };
        this.showSlide = async (el) => {
            return new Promise(resolve => {
                el.addEventListener('animationend', () => {
                    el.classList.remove('animated', 'fadeIn', this.slideSpeed);
                    resolve();
                }, { once: true });
                el.classList.add('animated', 'fadeIn', this.slideSpeed);
                el.style.display = 'block';
            });
        };
        this.setFrame = async (frame) => {
            const cur = this.element(`#slide-${this.frame}`);
            const nxt = this.element(`#slide-${frame}`);
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = undefined;
            }
            await this.hideSlide(cur);
            await this.showSlide(nxt);
            if (!this.clicked) {
                this.timeout = setTimeout(this.next, this.timeouts[frame]);
            }
            this.element(`#radio-${this.frame}`).removeAttribute('checked');
            this.element(`#radio-${frame}`).setAttribute('checked', 'checked');
            this.frame = frame;
            this.interrupt = false;
        };
        this.selectedFrame = (ev) => {
            this.clicked = true;
            if (this.interrupt) {
                return;
            }
            const frame = ev.target.dataset.frame;
            if (frame !== this.frame) {
                this.setFrame(frame);
            }
        };
        this.next = async () => {
            let frame = this.frame;
            if (frame < this.presentations.length - 1) {
                frame = frame + 1;
            }
            else {
                frame = 0;
            }
            this.interrupt = true;
            this.setFrame(frame);
        };
        this.show = async () => {
            this.el.style.display = 'block';
            await this._show('zoomIn', this.element('.help-container'));
        };
        this.hide = async () => {
            await this._hide('zoomOut', this.element('.help-container'));
            this.el.style.display = 'none';
        };
        this.close = async () => {
            if (this.slidesShown === false) {
                this.broadcast('view:shown');
            }
            await this.remove();
            this.el.innerHTML = '';
        };
        this.content = async () => {
            this.el.prepend(await this.help());
        };
        this.frame = 0;
        this.timeout = undefined;
        this.slidesShown = undefined;
        this.add('close', new buttons_1.CloseButtonView(this.selector, this));
        this.register('change', '.dialog.help .help-links', this.selectedFrame);
    }
    async help() {
        let html = [];
        html.push(`<div class="help-container">`);
        html.push(`<div class="presentation">`);
        html.push(`<div class="outer"><p class="inner animated flash infinite slow">Loading...</p></div>`);
        for (let idx = 0; idx < this.presentations.length; idx++) {
            const display = ' style="display: none;"';
            html.push(`<iframe src="${this.presentations[idx]}" id="slide-${idx}" frameborder="0" width="750" height="570"${display}></iframe>`);
        }
        html.push(`</div>`);
        html.push(`<div class="help-links">`);
        for (let idx = 0; idx < this.presentations.length; idx++) {
            const checked = this.frame === idx ? ' checked' : '';
            html.push(`<div class="mdc-form-field">
                <div class="mdc-radio">
                    <input class="mdc-radio__native-control" type="radio" id="radio-${idx}" data-frame="${idx}" name="radios"${checked}>
                    <div class="mdc-radio__background">
                    <div class="mdc-radio__outer-circle"></div>
                    <div class="mdc-radio__inner-circle"></div>
                    </div>
                    <div class="mdc-radio__ripple"></div>
                </div>
            </div>`);
        }
        html.push(`</div>`);
        html.push(`<div class="mdc-dialog__actions">`);
        html.push(this.get('close').preRender());
        html.push(`</div>`);
        html.push(`</div>`);
        return this.toDocumentFragment(html);
    }
    attachEvents() {
        this.slidesShown = false;
        this.broadcast('view:showing');
        const cur = this.element(`#slide-${this.frame}`);
        const wait = this.element(`.outer`);
        cur.addEventListener('load', async () => {
            wait.remove();
            this.broadcast('view:shown');
            this.slidesShown = true;
            await this.showSlide(cur);
            this.timeout = setTimeout(this.next, this.timeouts[this.frame]);
        }, { once: true });
        return super.attachEvents();
    }
    detachEvents() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        return super.detachEvents();
    }
}
exports.HelpView = HelpView;
