"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderView = void 0;
const lib_1 = require("../lib");
const buttons_1 = require("../buttons");
class HeaderView extends lib_1.AbstractView {
    constructor() {
        super();
        this.name = 'Header';
        this.selector = 'header';
        this.el = document.createElement('div');
        this.waitState = 0;
        this.unsetWaitState = () => {
            this.waitState--;
            if (this.waitState === 0) {
                this.element('.mdc-linear-progress').classList.remove('mdc-linear-progress--indeterminate');
            }
        };
        this.setWaitState = () => {
            if (this.waitState === 0) {
                this.element('.mdc-linear-progress').classList.add('mdc-linear-progress--indeterminate');
            }
            this.waitState++;
        };
        this.header = () => {
            const html = [];
            html.push(`<div class="mdc-top-app-bar__row">
            <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">`);
            html.push(this.get('menu').preRender());
            html.push(`<span class="mdc-top-app-bar__title">AIS PLTR</span>
            </section>
            <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end">`);
            html.push(this.get('zoomIn').preRender());
            html.push(this.get('zoomOut').preRender());
            html.push(this.get('lock').preRender());
            html.push(this.get('bookmarks').preRender());
            html.push(this.get('help').preRender());
            html.push(this.get('login').preRender());
            html.push(`</section>
            </div>
            <div role="progressbar" class="mdc-linear-progress" aria-valuemin="0"
                aria-valuemax="1" aria-valuenow="0">
                <div class="mdc-linear-progress__buffer"></div>
                <div class="mdc-linear-progress__bar mdc-linear-progress__primary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                </div>
                <div class="mdc-linear-progress__bar mdc-linear-progress__secondary-bar">
                    <span class="mdc-linear-progress__bar-inner"></span>
                </div>
            </div>`);
            return this.toDocumentFragment(html);
        };
        this.add('menu', new buttons_1.ShowDrawerButtonView(this.selector));
        this.add('zoomIn', new buttons_1.MapZoomInButtonView(this.selector));
        this.add('zoomOut', new buttons_1.MapZoomOutButtonView(this.selector));
        this.add('lock', new buttons_1.UnlockIconButtonView(this.selector));
        this.add('bookmarks', new buttons_1.BookmarksButtonView(this.selector));
        this.add('help', new buttons_1.HelpIconButtonView(this.selector));
        this.add('login', new buttons_1.LoginIconButtonView(this.selector));
        this.subscribe('view:showing', this.setWaitState);
        this.subscribe('view:hiding', this.setWaitState);
        this.subscribe('view:shown', this.unsetWaitState);
        this.subscribe('view:hidden', this.unsetWaitState);
        this.subscribe('set:waitstate', this.setWaitState);
        this.subscribe('unset:waitstate', this.unsetWaitState);
    }
    async render() {
        this.waitState = 0;
        this.el = document.querySelector(this.selector);
        this.beforeRender();
        await this.content();
        await this.afterRender();
    }
    async content() {
        this.el.prepend(this.header());
    }
}
exports.HeaderView = HeaderView;
