"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackHistoryButtonView = void 0;
const index_1 = require("../lib/index");
class TrackHistoryButtonView extends index_1.AbstractButtonView {
    constructor(selector) {
        super(selector);
        this.name = 'TrackHistoryButtonView';
        this.textA = 'Load Track History';
        this.textB = 'Not load Track History';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.init = false;
        this.locked = false;
        this.update = (ev) => {
            this.locked = ev.detail;
            this.render();
        };
        this.toggleLock = () => {
            this.broadcast('request:toggle:history');
            this.element('a.toggle-load-history').blur();
        };
        this.render = async () => {
            await this.beforeRender();
            this.element(this.replace, true).replaceWith(await this.content());
            await this.afterRender(false);
            if (!this.init) {
                this.init = true;
                this.broadcast('request:load:history');
            }
        };
        this.init = false;
        this.register('click', 'a.toggle-load-history', this.toggleLock);
        this.subscribe('response:load:history', this.update);
    }
    async content() {
        let has = this.locked;
        return this.toDocumentFragment(`
            <a class="mdc-list-item ${this.class} toggle-load-history" href="#" aria-current="page" tabindex="0">
                <i class="material-icons mdc-list-item__graphic" aria-hidden="true">${has && 'history' || 'update'}</i>
                <span class="mdc-list-item__text">${has && this.textA || this.textB}</span>
            </a>`);
    }
}
exports.TrackHistoryButtonView = TrackHistoryButtonView;
