"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipTableButtonView = void 0;
const index_1 = require("../lib/index");
class ShipTableButtonView extends index_1.AbstractButtonView {
    constructor(selector, style = 'icon') {
        super(selector);
        this.name = 'ShipTableShow';
        this.textA = 'Show Ship Table';
        this.textB = 'Ship Table shown';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.isShown = false;
        this.style = 'icon';
        this.tableShown = (ev) => {
            if (ev.detail.name === 'ShipTableView') {
                this.isShown = true;
                this.render();
            }
        };
        this.tableHidden = (ev) => {
            if (ev.detail.name === 'ShipTableView') {
                this.isShown = false;
                this.render();
            }
        };
        this.showTable = (ev) => {
            ev.preventDefault();
            this.broadcast('ships:table');
            this.element('.show-table').blur();
        };
        this.style = style;
        this.register('click', '.show-table', this.showTable);
        this.subscribe('view:showing', this.tableShown);
        this.subscribe('view:hidden', this.tableHidden);
    }
    async content() {
        if (this.style !== 'icon') {
            return this.toDocumentFragment(`
                <a class="mdc-list-item ${this.class} show-table" href="#" aria-current="page"${this.isShown && ' disabled="disabled"'}>
                    <i class="material-icons mdc-list-item__graphic" aria-hidden="true">table_chart</i>
                    <span class="mdc-list-item__text">${!this.isShown && this.textA || this.textB}</span>
                </a>`);
        }
        return this.toDocumentFragment(`<button
            class="${this.class} show-table ${this.classList}"
            aria-label="${!this.isShown && this.textA || this.textB}"${this.isShown && ' disabled="disabled"'}>table_chart</button>`);
    }
}
exports.ShipTableButtonView = ShipTableButtonView;
