"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageView = void 0;
const lib_1 = require("../lib");
class PageView extends lib_1.AbstractView {
    constructor() {
        super(...arguments);
        this.name = 'Page';
        this.selector = 'body';
        this.body = () => {
            const html = [];
            html.push(`<aside class="mdc-drawer mdc-drawer--modal mdc-top-app-bar--fixed-adjust"></aside>
            <header class="mdc-top-app-bar app-bar"></header>
            <div class="mdc-drawer-scrim"></div>
            <main class="main-content">
                    <div class="map"></div>
                    <div class="data-table-container shipdata"></div>
                    <div class="data-table-container positions"></div>
                    <div class="dialog shipdata__details"></div>
                    <div class="dialog help"></div>
                    <div class="dialog login"></div>
                    <div class="dialog alert"></div>
            </main>
            <div class="mdc-dialog"></div>`);
            return this.toDocumentFragment(html);
        };
    }
    async content(ev) {
        this.el.prepend(this.body());
    }
}
exports.PageView = PageView;
