"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Description = {
    _id: 'ObjectID',
    AIS: 'AIS message number',
    Channel: 'Chanel on which the Messages was send',
    MMSI: 'Unique ship identification number (MMSI)',
    TimeStamp: 'Received at (UTC)',
    CreatedAt: 'Created at (UTC)',
    CreatedBy: 'Created By',
    Sender: 'Sender'
};
