"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailsButtonView = void 0;
const index_1 = require("../lib/index");
class DetailsButtonView extends index_1.AbstractButtonView {
    constructor(selector, ship) {
        super(selector);
        this.name = 'DetailsButtonView';
        this.textA = 'Details';
        this.textB = 'Details';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.isShown = false;
        this.detailsShown = (ev) => {
            if (ev.detail.name === 'ShipDetailsView' && this.ship.MMSI === ev.detail.view.ship.MMSI) {
                this.isShown = true;
                this.render();
            }
        };
        this.detailsHidden = (ev) => {
            if (ev.detail.name === 'ShipDetailsView' && this.ship.MMSI === ev.detail.view.ship.MMSI) {
                this.isShown = false;
                this.render();
            }
        };
        this.showDetails = () => {
            this.broadcast('ship:detail', { ship: this.ship });
            this.element('button.show-details').blur();
        };
        this.ship = ship;
        this.register('click', 'button.show-details', this.showDetails);
        this.subscribe('view:showing', this.detailsShown);
        this.subscribe('view:hidden', this.detailsHidden);
    }
    async content(ev) {
        return this.toDocumentFragment(`<button
            class="${this.class} show-details ${this.classList}"
            aria-label="${!this.isShown && this.textA || this.textB}"${this.isShown && ' disabled="disabled"'}>${!this.isShown && this.textA || this.textB}</button>`);
    }
}
exports.DetailsButtonView = DetailsButtonView;
