"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseIconButtonView = void 0;
const index_1 = require("../lib/index");
class CloseIconButtonView extends index_1.AbstractButtonView {
    constructor(selector, view) {
        super(selector);
        this.name = 'Close';
        this.textA = 'Close';
        this.textB = '';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.close = async () => {
            await this.view.close();
        };
        this.view = view;
        this.register('click', 'button.close', this.close);
    }
    async content(ev) {
        return this.toDocumentFragment(`<button
            class="${this.class} close ${this.classList}"
            aria-label="${this.textA}">clear</button>`);
    }
}
exports.CloseIconButtonView = CloseIconButtonView;
