"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractButtonView = void 0;
const uuid_1 = require("uuid");
const AbstractView_1 = require("./AbstractView");
class AbstractButtonView extends AbstractView_1.AbstractView {
    constructor(selector, ship) {
        super();
        this.interrupted = false;
        this.interrupt = async () => {
            this.interrupted = true;
            this.render();
        };
        this.continue = async () => {
            this.interrupted = false;
            this.render();
        };
        this.render = async (ev) => {
            await this.beforeRender();
            this.element(this.replace).replaceWith(await this.content(ev));
            await this.afterRender(false);
        };
        this.selector = selector;
        this.ship = ship;
        this.uuid = uuid_1.v4();
        this.class = `btn-${this.uuid}`;
        this.replace = `.${this.class}`;
    }
    get disabled() {
        if (this.interrupted) {
            return ' disabled="disabled';
        }
        return '';
    }
    preRender(textA, textB, classList) {
        this.textA = textA || this.textA;
        this.textB = textB || this.textB;
        this.classList = classList || this.classList;
        return `<button class="${this.class}"></button>`;
    }
}
exports.AbstractButtonView = AbstractButtonView;
