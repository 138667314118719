"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AbstractNmeaIterableModel_1 = require("../AbstractNmea/AbstractNmeaIterableModel");
const NmeaShipdataFormatter_1 = require("./NmeaShipdataFormatter");
const NmeaShipdataParser_1 = require("./NmeaShipdataParser");
const NmeaShipdataMetadata_1 = require("./NmeaShipdataMetadata");
class NmeaShipdata extends AbstractNmeaIterableModel_1.AbstractNmeaIterableModel {
    constructor(collection, model) {
        super();
        this.Data = {
            AISversion: 0,
            IMOnumber: 0,
            CallSign: '',
            Name: '',
            ShipType: 0,
            PositionType: 0,
            DimA: 0,
            DimB: 0,
            DimC: 0,
            DimD: 0,
            Draught: 0,
            Destination: '',
            ETA: ''
        };
        this.collection = collection;
        this.parser = new NmeaShipdataParser_1.NmeaShipdataParser(this);
        this.formatter = new NmeaShipdataFormatter_1.NmeaShipdataFormatter(this);
        if (model) {
            this.fromModel(model);
        }
    }
    get AISversion() { return this.Data.AISversion; }
    get IMOnumber() { return this.Data.IMOnumber; }
    get CallSign() { return this.Data.CallSign; }
    get Name() { return this.Data.Name; }
    get ShipType() { return this.Data.ShipType; }
    get PositionType() { return this.Data.PositionType; }
    get DimA() { return this.Data.DimA; }
    get DimB() { return this.Data.DimB; }
    get DimC() { return this.Data.DimC; }
    get DimD() { return this.Data.DimD; }
    get Draught() { return this.Data.Draught; }
    get Destination() { return this.Data.Destination; }
    get ETA() { return this.Data.ETA; }
    set AISversion(AISversion) { this.Data.AISversion = AISversion; }
    set IMOnumber(IMOnumber) { this.Data.IMOnumber = IMOnumber; }
    set CallSign(CallSign) { this.Data.CallSign = CallSign; }
    set Name(Name) { this.Data.Name = Name; }
    set ShipType(ShipType) { this.Data.ShipType = ShipType; }
    set PositionType(PositionType) { this.Data.PositionType = PositionType; }
    set DimA(DimA) { this.Data.DimA = DimA; }
    set DimB(DimB) { this.Data.DimB = DimB; }
    set DimC(DimC) { this.Data.DimC = DimC; }
    set DimD(DimD) { this.Data.DimD = DimD; }
    set Draught(Draught) { this.Data.Draught = Draught; }
    set Destination(Destination) { this.Data.Destination = Destination; }
    set ETA(ETA) { this.Data.ETA = ETA; }
    fromModel(model) {
        const fields = [
            '_id',
            'AIS', 'Channel', 'MMSI', 'TimeStamp',
            'Data',
            'CreatedAt', 'CreatedBy', 'UpdatedAt', 'UpdatedBy',
            'Sender',
            'RAW'
        ];
        for (const field of fields) {
            this[field] = model[field];
        }
        return this;
    }
    async toModel(bin, utc, raw) {
        const data = await this.parser.parse(bin, utc, raw);
        const result = { _id: this.collection.database.createObjectID(), ...data };
        return this.fromModel(result);
    }
    toJSON() {
        const fields = [
            '_id',
            'AIS', 'Channel', 'MMSI', 'TimeStamp',
            'Data',
            'CreatedAt', 'CreatedBy', 'UpdatedAt', 'UpdatedBy',
            'Sender',
            'RAW'
        ];
        return super._toJSON(fields);
    }
    toDB() {
        const head = [
            'UpdatedAt', 'UpdatedBy', 'TimeStamp'
        ];
        const tail = [
            '_id', 'Data',
            'AIS', 'Channel', 'MMSI',
            'CreatedAt', 'CreatedBy',
            'RAW'
        ];
        const ret = {
            filter: { MMSI: this.MMSI },
            head: super._toDB(head),
            tail: super._toDB(tail)
        };
        return ret;
    }
    isValid() {
        return true;
    }
    compare(other) {
        const fields = [
            'IMOnumber', 'CallSign', 'Name', 'ShipType', 'DimA', 'DimB', 'DimC',
            'DimD', 'Draught', 'Destination', 'ETA'
        ];
        return fields.every((key) => this[key] === other[key]);
    }
    toReport() {
        const fields = [
            'MMSI', 'AISversion', 'IMOnumber', 'CallSign',
            'Name', 'ShipType', 'PositionType', 'DimA', 'DimB', 'DimC', 'DimD',
            'Draught', 'Destination', 'ETA', 'Sender', 'CreatedAt', 'CreatedBy'
        ];
        return super._toReport(NmeaShipdataMetadata_1.Description, fields);
    }
    toReportDiff(other) {
        const fields = [
            'MMSI', 'AISversion', 'IMOnumber', 'CallSign',
            'Name', 'ShipType', 'PositionType', 'DimA', 'DimB', 'DimC', 'DimD',
            'Draught', 'Destination', 'ETA', 'Sender', 'CreatedAt', 'CreatedBy'
        ];
        return super._toReportDiff(NmeaShipdataMetadata_1.Description, fields, other);
    }
    toInfo() {
        const data = [
            {
                key: 'Class',
                val: 'NmeaPosition'
            },
            {
                key: 'MMSI',
                val: String(this.MMSI)
            },
            {
                key: 'Name',
                val: this.Name
            },
            {
                key: 'Sender',
                val: this.format('Sender')
            }
        ];
        return data;
    }
}
exports.NmeaShipdata = NmeaShipdata;
