"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnlockIconButtonView = void 0;
const index_1 = require("../lib/index");
class UnlockIconButtonView extends index_1.AbstractButtonView {
    constructor(selector, style = 'icon') {
        super(selector);
        this.name = 'LockButtonView';
        this.textA = 'Lock';
        this.textB = 'Unlock';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.init = false;
        this.style = 'icon';
        this.unlock = (ev) => {
            ev.preventDefault();
            this.broadcast('request:unlock:mmsi');
            this.element('.toggle-lock').blur();
        };
        this.render = async (ev) => {
            await this.beforeRender();
            this.element(this.replace, true).replaceWith(await this.content(ev));
            await this.afterRender(false);
            if (!this.init) {
                this.init = true;
                this.broadcast('request:locked:mmsi');
            }
        };
        this.init = false;
        this.style = style;
        this.register('click', '.toggle-lock', this.unlock);
        this.subscribe(`response:locked:mmsi`, this.render);
    }
    async content(ev) {
        let has = ev && ev.detail || false;
        if (this.style !== 'icon') {
            return this.toDocumentFragment(`
                <a class="mdc-list-item ${this.class} toggle-lock" href="#" aria-current="page"${has && ' disabled="disabled"'}>
                    <i class="material-icons mdc-list-item__graphic" aria-hidden="true">${has && 'lock' || 'lock_open'}</i>
                    <span class="mdc-list-item__text">${!has && this.textA || this.textB}</span>
                </a>`);
        }
        return this.toDocumentFragment(`<button
            class="${this.class} toggle-lock ${this.classList}"${!has && ' disabled="disabled"'}
            aria-label="${has && this.textB || this.textA}">${has && 'lock' || 'lock_open'}</button>`);
    }
}
exports.UnlockIconButtonView = UnlockIconButtonView;
