"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LockButtonView = void 0;
const index_1 = require("../lib/index");
class LockButtonView extends index_1.AbstractButtonView {
    constructor(selector, ship) {
        super(selector);
        this.name = 'LockButtonView';
        this.textA = 'Lock';
        this.textB = 'Unlock';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.init = false;
        this.locked = false;
        this.update = (ev) => {
            this.locked = false;
            if (this.ship.MMSI === ev.detail) {
                this.locked = true;
            }
            this.render();
        };
        this.toggleLock = () => {
            if (this.locked) {
                this.broadcast('request:unlock:mmsi');
            }
            else {
                this.broadcast('request:lock:mmsi', { MMSI: this.ship.MMSI });
            }
            this.element('button.toggle-lock').blur();
        };
        this.render = async () => {
            await this.beforeRender();
            this.element(this.replace, true).replaceWith(await this.content());
            await this.afterRender(false);
            if (!this.init) {
                this.init = true;
                this.broadcast('request:locked:mmsi');
            }
        };
        this.ship = ship;
        this.init = false;
        this.register('click', 'button.toggle-lock', this.toggleLock);
        this.subscribe(`response:locked:mmsi`, this.update);
    }
    async content() {
        let has = this.locked;
        return this.toDocumentFragment(`<button
            class="${this.class} toggle-lock ${this.classList}${has && ' mdc-button--outlined' || ''}"
            aria-label="${has && this.textB || this.textA}">${has && this.textB || this.textA}</button>`);
    }
}
exports.LockButtonView = LockButtonView;
