"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class AbstractNmeaFormatter {
    constructor(model) {
        this.model = model;
    }
    lookup(value, lookup) {
        const f = lookup.find(item => item.value === value);
        if (f) {
            return f.description;
        }
        return value;
    }
}
exports.AbstractNmeaFormatter = AbstractNmeaFormatter;
