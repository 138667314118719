"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const AbstractNmeaParser_1 = require("../AbstractNmea/AbstractNmeaParser");
class NmeaDefaultParser extends AbstractNmeaParser_1.AbstractNmeaParser {
    async parse(bin, utc, raw) {
        const TimeStamp = new Date(utc.format());
        const result = {
            AIS: this.parse_int(bin.substr(0, 6)),
            Channel: raw[0].split(',')[4],
            MMSI: this.parse_int(bin.substr(8, 30)),
            TimeStamp: TimeStamp,
            Sender: [{
                    Name: this.model.collection.Sender,
                    TimeStamp: TimeStamp
                }],
            CreatedAt: TimeStamp,
            CreatedBy: this.model.collection.Sender,
            UpdatedAt: TimeStamp,
            UpdatedBy: this.model.collection.Sender,
            RAW: raw
        };
        return result;
    }
}
exports.NmeaDefaultParser = NmeaDefaultParser;
