"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShipDetailsView = void 0;
const ais_tools_1 = require("ais-tools");
const lib_1 = require("../lib");
const buttons_1 = require("../buttons");
const ShipImageView_1 = require("./ShipImageView");
class ShipDetailsView extends lib_1.AbstractView {
    constructor(ship) {
        super();
        this.name = 'ShipDetailsView';
        this.selector = '.dialog.shipdata__details';
        this.format = 'HH:mm:ss';
        this.shipdataFields = [
            'MMSI', 'AIS',
            'Channel', 'AISversion',
            'IMOnumber', 'CallSign',
            'TimeStamp', 'PositionType',
            'Dimensions', 'Draught',
            'Destination', 'ETA',
        ];
        this.positionFields = [
            'MMSI', 'AIS',
            'Latitude', 'Longitude',
            'ROT', 'SOG',
            'COG', 'TrueHeading',
            'DistanceMoved', 'PositionAccuracy',
            'TimeStamp', 'TimeStampStatus',
        ];
        this.close = async () => {
            await this.remove();
        };
        this.stop = (ev) => {
            ev.stopPropagation();
        };
        this.show = async () => {
            this.el.style.display = 'block';
            await this._show('zoomIn', this.element('.details-card'));
        };
        this.hide = async () => {
            await this._hide('zoomOut', this.element('.details-card'));
            this.el.style.display = 'none';
        };
        this.clipboard = (ev) => {
            const target = ev.target;
            const a = target.closest('[class*="shipdata-"]');
            const b = target.closest('[class*="position-"]');
            const c = a || b;
            if (c) {
                const cont = c.querySelector('.content').innerText;
                const el = document.createElement('textarea');
                el.value = cont;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            }
        };
        this.shipdata = () => {
            this.element('.details-card__secondary.shipdata').style.display = 'block';
            this.element('.details-card__secondary.position').style.display = 'none';
            this.broadcast('ship:data:shown');
            this.broadcast('ship:position:hidden');
        };
        this.position = () => {
            this.element('.details-card__secondary.shipdata').style.display = 'none';
            this.element('.details-card__secondary.position').style.display = 'block';
            this.broadcast('ship:data:hidden');
            this.broadcast('ship:position:shown');
        };
        this.animate = (model) => {
            let fields, selectors;
            if (model instanceof ais_tools_1.Ship) {
                fields = this.shipdataFields;
                fields.push('Name');
                fields.push('ShipType');
                selectors = fields.map(field => `.shipdata-${field} .content`);
            }
            if (model instanceof ais_tools_1.NmeaPositionFeature) {
                fields = this.positionFields;
                fields.push('NavigationStatus');
                selectors = fields.map(field => `.position-${field} .content`);
            }
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                const e = this.el.querySelector(selectors[i]);
                const p = e.parentElement;
                if (p) {
                    let s = '';
                    if ('TimeStamp,CreatedAt'.indexOf(field) >= 0) {
                        s = model.format(field, this.format);
                    }
                    else {
                        s = model.format(field);
                    }
                    if (e.innerText !== s) {
                        e.innerText = s;
                        p.classList.add('animated', 'flash');
                        p.addEventListener('animationend', () => p.classList.remove('animated', 'flash'), { once: true });
                    }
                }
            }
        };
        this.content = async () => {
            this.el.prepend(await this.dialog());
        };
        this.ship = ship;
        this.register('click', this.close);
        this.register('click', '.details-card', this.stop);
        this.register('click', '.details-card', this.stop);
        this.register('click', '.details-card', this.clipboard);
        this.subscribe('ship:details:data', this.shipdata);
        this.subscribe('ship:details:position', this.position);
        this.add('image', new ShipImageView_1.ShipImageView(this.selector, this.ship));
        this.add('shipTab', new buttons_1.ShipTabButtonView(this.selector, this.ship));
        this.add('positionTab', new buttons_1.PositionTabButtonView(this.selector, this.ship));
        this.add('close', new buttons_1.CloseButtonView(this.selector, this));
    }
    async dialog() {
        let html = [];
        html.push(`<div class="mdc-card details-card mdc-elevation--z16 unselectable">`);
        html.push(`<div class="details-card__wrapper">`);
        html.push(this.get('image').preRender());
        html.push(`<div class="details-card__primary">`);
        html.push(`<h2 class="details-card__title mdc-typography mdc-typography--headline6 shipdata-Name"><span class="content">${this.ship.format('Name')}</span></h2>`);
        html.push(`<h3 class="details-card__subtitle mdc-typography mdc-typography--subtitle2 shipdata-ShipType""><span class="content">${this.ship.format('ShipType')}</span></h3>`);
        html.push(`<h3 class="details-card__subtitle mdc-typography mdc-typography--subtitle2 position-NavigationStatus"><span class="content">${this.ship.position.format('NavigationStatus')}</span></h3>`);
        html.push(`</div>`);
        html.push(`</div>`);
        html.push(`<div class="details-card__secondary shipdata mdc-typography mdc-typography--body2 unselectable">`);
        for (const field of this.shipdataFields) {
            if ('TimeStamp,CreatedAt'.indexOf(field) >= 0) {
                html.push(`<p class="shipdata-${field}"><span class="label">${field}</span> <span class="content">${this.ship.format(field, this.format)}</span></p>`);
            }
            else if (field === 'ETA') {
                html.push(`<p class="shipdata-${field}"><span class="label">${field}</span> <span class="content">${this.ship.format(field, 'MM-DD-YYYY')}</span></p>`);
            }
            else {
                html.push(`<p class="shipdata-${field}"><span class="label">${field}</span> <span class="content">${this.ship.format(field)}</span></p>`);
            }
        }
        html.push(`</div>`);
        html.push(`<div class="details-card__secondary position mdc-typography mdc-typography--body2 unselectable" style="display: none;">`);
        for (const field of this.positionFields) {
            if ('TimeStamp,CreatedAt'.indexOf(field) >= 0) {
                html.push(`<p class="position-${field}"><span class="label">${field}</span> <span class="content">${this.ship.position.format(field, 'HH:mm:ss')}</span></p>`);
            }
            else {
                html.push(`<p class="position-${field}"><span class="label">${field}</span> <span class="content">${this.ship.position.format(field)}</span></p>`);
            }
        }
        html.push(`</div>`);
        html.push(`<div class="mdc-card__actions">`);
        html.push(`<div class="mdc-card__action-buttons">`);
        html.push(this.get('shipTab').preRender());
        html.push(this.get('positionTab').preRender());
        html.push(`</div>`);
        html.push(`<div class="mdc-card__action-icons">`);
        html.push(this.get('close').preRender());
        html.push(`</div>`);
        html.push(`</div>`);
        html.push(`</div>`);
        return this.toDocumentFragment(html);
    }
    detachEvents() {
        this.ship.off('ship', this.animate);
        this.ship.off('position', this.animate);
        this.ship.unsubscribe();
        return super.detachEvents();
    }
    attachEvents() {
        this.ship.on('ship', this.animate);
        this.ship.on('position', this.animate);
        this.ship.subscribe();
        return super.attachEvents();
    }
}
exports.ShipDetailsView = ShipDetailsView;
