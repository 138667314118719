"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapZoomInButtonView = void 0;
const index_1 = require("../lib/index");
class MapZoomInButtonView extends index_1.AbstractButtonView {
    constructor(selector) {
        super(selector);
        this.name = 'MapZoomInButtonView';
        this.textA = 'Zoom In';
        this.textB = 'Max Zoom reached';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.zoomIn = () => {
            this.broadcast('map:zoom:in');
            this.element('button.zoom-in').blur();
        };
        this.register('click', 'button.zoom-in', this.zoomIn);
        this.subscribe('map:zoom', this.render);
    }
    async content(ev) {
        let can = ev ? ev.detail.canZoomIn : false;
        return this.toDocumentFragment(`<button 
            class="${this.class} zoom-in ${this.classList}" 
            aria-label="${can && this.textA || this.textB}"${!can && ' disabled="disabled"'}>add</button>`);
    }
}
exports.MapZoomInButtonView = MapZoomInButtonView;
