"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackCollectionButtonView = void 0;
const index_1 = require("../lib/index");
class TrackCollectionButtonView extends index_1.AbstractButtonView {
    constructor(selector, style = 'icon') {
        super(selector);
        this.name = 'TrackCollectionButtonView';
        this.textA = 'Track Collection';
        this.textB = 'Track Collection is empty';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.style = 'icon';
        this.update = () => {
            this.broadcast('request:track');
        };
        this.showPanel = () => {
            this.broadcast('track:collection:panel');
            this.element('.show-track-collection').blur();
        };
        this.style = style;
        this.register('click', '.show-track-collection', this.showPanel);
        this.subscribe('ship:track', this.update);
        this.subscribe('response:track', this.render);
    }
    async content(ev) {
        let can = ev && ev.detail || false;
        if (this.style !== 'icon') {
            return this.toDocumentFragment(`
                <a class="mdc-list-item ${this.class} show-track-collection" href="#" aria-current="page"${!can && ' disabled="disabled"'}>
                    <i class="material-icons mdc-list-item__graphic" aria-hidden="true">list</i>
                    <span class="mdc-list-item__text">${can && this.textA || this.textB}</span>
                </a>`);
        }
        return this.toDocumentFragment(`<button
            class="${this.class} show-track-collection ${this.classList}"
            aria-label="${can && this.textA || this.textB}"${!can && ' disabled="disabled"'}>list</button>`);
    }
}
exports.TrackCollectionButtonView = TrackCollectionButtonView;
