"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseButtonView = void 0;
const index_1 = require("../lib/index");
class CloseButtonView extends index_1.AbstractButtonView {
    constructor(selector, view) {
        super(selector);
        this.name = 'Close';
        this.textA = 'Close';
        this.textB = '';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.isDisabled = false;
        this.close = async () => {
            await this.view.close();
        };
        this.view = view;
        this.register('click', 'button.close', this.close);
    }
    set disable(isDisabled) {
        this.isDisabled = isDisabled;
        this.render();
    }
    async content() {
        return this.toDocumentFragment(`<button
            class="${this.class} close ${this.classList}"${this.isDisabled && ' disabled="disabled"'}
            aria-label="${this.textA}">${this.textA}</button>`);
    }
}
exports.CloseButtonView = CloseButtonView;
