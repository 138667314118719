"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptButtonView = void 0;
const index_1 = require("../lib/index");
class AcceptButtonView extends index_1.AbstractButtonView {
    constructor(selector, text, view) {
        super(selector);
        this.name = 'Accept';
        this.textA = '';
        this.textB = '';
        this.classList = 'mdc-button mdc-card__action mdc-card__action--button';
        this.isDisabled = true;
        this.close = async () => {
            await this.view.accept();
        };
        this.view = view;
        this.textA = text;
        this.register('click', 'button.accept', this.close);
    }
    set disable(isDisabled) {
        this.isDisabled = isDisabled;
        this.render();
    }
    async content() {
        return this.toDocumentFragment(`<button
            class="${this.class} accept ${this.classList}"${this.isDisabled && ' disabled="disabled"'}
            aria-label="${this.textA}">${this.textA}</button>`);
    }
}
exports.AcceptButtonView = AcceptButtonView;
