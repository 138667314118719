"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginIconButtonView = void 0;
const index_1 = require("../lib/index");
class LoginIconButtonView extends index_1.AbstractButtonView {
    constructor(selector) {
        super(selector);
        this.name = 'LoginIconButtonView';
        this.textA = 'login';
        this.textB = 'logout';
        this.classList = 'mdc-icon-button material-icons mdc-top-app-bar__action-item--unbounded';
        this.init = false;
        this.text = 'login';
        this.update = (ev) => {
            this.text = ev.detail ? this.textB : this.textA;
            this.render();
        };
        this.showHelp = () => {
            if (this.text === 'login') {
                this.broadcast('request:show:login');
                this.element('button.login-btn').blur();
            }
            else {
                this.broadcast('request:logout');
            }
        };
        this.render = async () => {
            await this.beforeRender();
            this.element(this.replace, true).replaceWith(await this.content());
            await this.afterRender(false);
            if (!this.init) {
                this.init = true;
                this.broadcast('request:login');
            }
        };
        this.register('click', 'button.login-btn', this.showHelp);
        this.subscribe(`response:login`, this.update);
    }
    async content() {
        return this.toDocumentFragment(`<button
            class="${this.class} login-btn ${this.classList}"
            aria-label="${this.text}">${this.text}</button>`);
    }
}
exports.LoginIconButtonView = LoginIconButtonView;
