"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerView = void 0;
const drawer_1 = require("@material/drawer");
const lib_1 = require("../lib");
const buttons_1 = require("../buttons");
class DrawerView extends lib_1.AbstractLayer {
    constructor() {
        super();
        this.name = 'Drawer';
        this.selector = 'aside';
        this.toggleDrawer = () => {
            if (!this.drawer) {
                return;
            }
            this.drawer.open = !this.drawer.open;
        };
        this.drawerClose = () => {
            if (!this.drawer) {
                return;
            }
            this.drawer.open = false;
        };
        this.aside = () => {
            const html = [];
            html.push(`<div class="mdc-drawer__content">
            <nav class="mdc-list">`);
            html.push(this.get('showAll').preRender());
            html.push(this.get('history').preRender());
            html.push(this.get('trackCol').preRender());
            html.push(this.get('ships').preRender());
            html.push(`</nav>
            </div>`);
            return this.toDocumentFragment(html);
        };
        this.add('trackCol', new buttons_1.TrackCollectionButtonView(this.selector, 'nav'));
        this.add('showAll', new buttons_1.MapShowAllFeaturesButtonView(this.selector, 'nav'));
        this.add('ships', new buttons_1.ShipTableButtonView(this.selector, 'nav'));
        this.add('history', new buttons_1.TrackHistoryButtonView(this.selector));
        this.subscribe('menu:clicked', this.toggleDrawer);
        this.subscribe('map:clicked', this.drawerClose);
        this.subscribe('track:collection:panel', this.drawerClose);
        this.subscribe('map:show:all:features', this.drawerClose);
        this.subscribe('ships:table', this.drawerClose);
        this.subscribe('request:unlock:mmsi', this.drawerClose);
        this.subscribe('request:statistics:show', this.drawerClose);
    }
    async content() {
        this.el.prepend(this.aside());
    }
    async render() {
        this.el = document.querySelector(this.selector);
        this.beforeRender();
        await this.content();
        this.drawer = new drawer_1.MDCDrawer(this.el);
        await this.afterRender(false);
    }
}
exports.DrawerView = DrawerView;
